import React, { createContext, useEffect, useReducer } from "react";
import axios from "axios.js";
import { MatxLoading } from "app/components";
import api from "api";
import { useNavigate } from "react-router-dom";
import { useSocket } from "app/SocketContext";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
  } else {
    localStorage.clear();
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const socket = useSocket();
  const login = async (email, password) => {
    const response = await api.User.login({
      email,
      password,
    });
    console.log(response);
    if (response.status === 201) {
      const { token, user } = response.data;
      console.log(user, "logged");

      setSession(token);
       if(user.role==="franchise"){localStorage.setItem("franchise_id", user?.franchise_id);}
      console.log(user);
      dispatch({
        type: "LOGIN",
        payload: {
          user: user,
        },
      });

      if (user?.role === "franchise") {  
      socket.emit("joinFranchise", user.franchise_id);}
      else{socket.emit("join", "adminRoom");}
      navigate("/dashboard/default");
    } else {
      console.log("Email or password is incorrect");
    }
  };

  const register = async (email, username, password) => {
    const response = await axios.post("/api/auth/register", {
      email,
      username,
      password,
    });

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    localStorage.removeItem("franchise_id");
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const franchiseId = localStorage.getItem("franchise_id");
        if (accessToken) {
          console.log("yes local storage");
          console.log(accessToken);
          setSession(accessToken);
          const response = await api.User.Refresh();
          
          const userData = response.data;
          console.log(userData , franchiseId);

           if (userData?.role ==="franchise") {  
      socket.emit("joinFranchise", franchiseId);}
      else{socket.emit("join", "adminRoom");}


          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: true,
              user: userData,
            },
          });
        } else {
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
