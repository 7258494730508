

const MatxLogo = ({ className }) => {



  return (
    <div>
      {' '}
      <img
        width="40px"
        height="40px"
        // width="100%"
        alt="Register"
        src="/assets/images/illustrations/thalinew.png "
        style={{ borderRadius: '50%' }}
      />
    </div>
 
  );
};

export default MatxLogo;
