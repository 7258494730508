import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import routes from "./routes";
import axios from "axios";

axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("accessToken");

const App = () => {
  const content = useRoutes(routes);
  return (
    <SettingsProvider>
      <MatxTheme>
        <AuthProvider>{content}</AuthProvider>
      </MatxTheme>
    </SettingsProvider>
  );
};

export default App;
