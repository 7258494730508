export const authRoles = {
  sa: ['super_admin'], // Only Super Admin has access
  Super_employee:['super_admin','employee','employee2'], // superadmin and employee has access
  franchise:['franchise'],
  restaurant: [ 'restaurant'], // Only  restaurant has access
  Super_franchise: ['super_admin', 'franchise' ], // Only SA & franchise has access
  four: ['super_admin', 'employee', 'franchise','employee2' ], // Everyone has access
  super_employee2: ['super_admin','employee2' ],
  super_employees: ['super_admin', 'employee', 'franchise','employee2' ],
  super_franc_employe:['super_admin', 'employee', 'franchise']
};
