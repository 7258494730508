import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.headers.common["X-Requested-With"] = " XMLHttpRequest";
axios.defaults.headers.post["Content-Type"] = " application/json";

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
});

console.log(localStorage.getItem("accessToken"), "ots working");

console.log(localStorage.getItem("accessToken"), "ots working");
// setAuthorizationHeader();
const api = {
  IMAGE_URL: "https://orderthali.s3.ap-south-1.amazonaws.com/",
  User: {
    login: (data) => axios.post("user/login", data),
    Refresh: (data) => axios.post("user/verify-token", data),
    Update: (data) => axios.post("user/update-password", data),
  },
  Franchise: {
    add: (data) => axios.post("superadmin/add-franchise", data),
    getAll: (data) => axios.get("superadmin/franchises-name", data),
    update: (data) => axios.patch("superadmin/update-franchise", data),
    delete: (data) => axios.delete("superadmin/delete-franchise", data),
    OrderMargin: (franchise_id, restuarant_id) =>
      axios.get(`franchise/order-margin/${franchise_id}/${restuarant_id}`),
  },
  Restaurant: {
    add: (data) => axios.post("restaurant/add-restaurant", data),

    update: (data) => axios.patch("restaurant/update-restaurant", data),
    delete: (data) => axios.delete("restaurant/delete-restaurant", data),

    RestaurantName: (data) => axios.get("restaurant/restaurant-names", data),
  },
  Coupon: {
    addCoupon: (data) => axios.post("coupon/add-coupon", data),
    update: (data) => axios.patch("coupon/update-coupon", data),
    getAll: (data) => axios.get("coupon/get-all-coupons", data),
    RestaurantNames: (data) => axios.get("restaurant/names", data),
  },

  Category: {
    AddCategory: (data) => axios.post("category/add-category", data),
    CategoryList: (data) => axios.get("category/get-categories", data),
    Delete: (data) => axios.get("category/delete-category", data),
    Update: (data) => axios.patch("category/update-category", data),
    CategoryNames: (data) => axios.get("category/category-name", data),
  },

  Addons: {
    Create: (data) => axios.post("restaurant/add-addons", data),
    Update: (data) => axios.patch("restaurant/update-addons", data),
  },

  Login: {
    login: (data) => axios.post("login", data),
  },
  Thali: {
    add: (data) => axios.post("thali/add-thali", data),
    get: (query) =>
      axios.get("thali", {
        params: query,
      }),
    getThali: (id) => axios.get("thali/" + id),
    update: (data) => axios.put("thali", data),
    remove: (id) => axios.delete("thali/" + id),
  },
  Upload: {
    images: (data) => axios.post("upload/images", data),
  },
  Catalog: {
    Add: (data) => axios.post("catalog/add-catalog", data),
    CatalogList: (data) => axios.get("catalog/get-catalog", data),
  },
};




export default api;
