import { Box, ButtonBase, Icon, styled } from "@mui/material";

import useSettings from "app/hooks/useSettings";
import useAuth from "app/hooks/useAuth";
import React, { Fragment, useEffect,} from "react";
import { NavLink } from "react-router-dom";
import { Paragraph, Span } from "../Typography";
import MatxVerticalNavExpansionPanel from "./MatxVerticalNavExpansionPanel";

const ListLabel = styled(Paragraph)(({ theme, mode }) => ({
  fontSize: "12px",
  marginTop: "20px",
  marginLeft: "15px",
  marginBottom: "10px",
  textTransform: "uppercase",
  display: mode === "compact" && "none",
  color: theme.palette.text.custom,
}));

const ExtAndIntCommon = {
  display: "flex",
  overflow: "hidden",
  borderRadius: "4px",
  height: 44,
  whiteSpace: "pre",
  marginBottom: "8px",
  textDecoration: "none",
  justifyContent: "space-between",
  transition: "all 150ms ease-in",
  // '&:hover': { background: 'rgba(255, 255, 255, 0.08)' },
  "&.compactNavItem": {
    overflow: "hidden",
    justifyContent: "center !important",
  },
  "& .icon": {
    fontSize: "18px",
    paddingLeft: "16px",
    paddingRight: "16px",
    verticalAlign: "middle",
  },
};
const ExternalLink = styled("a")(({ theme }) => ({
  ...ExtAndIntCommon,
  color: theme.palette.text.custom,
}));

const InternalLink = styled(Box)(({ theme }) => ({
  "& a": {
    ...ExtAndIntCommon,
    color: theme.palette.text.custom,
  },
  "& .navItemActive": {
    backgroundColor: "#f6fa0a",
  },
}));

const StyledText = styled(Span)(({ mode, textColor }) => ({
  fontSize: "0.875rem",
  paddingLeft: "0.8rem",
  display: mode === "compact" && "none",
  color: textColor,
}));

const BulletIcon = styled("div")(({ theme }) => ({
  padding: "2px",
  marginLeft: "24px",
  marginRight: "8px",
  overflow: "hidden",
  borderRadius: "300px",
  background: theme.palette.text.custom,
}));

const BadgeValue = styled("div")(() => ({
  padding: "1px 8px",
  overflow: "hidden",
  borderRadius: "300px",
}));

const MatxVerticalNav = ({ items, onItemClick, isMobile }) => {
  const { user } = useAuth();
  const { settings } = useSettings();
  const { mode } = settings.layout1Settings.leftSidebar;
  const role = user.role;

  useEffect(() => {
    console.log(role);
    console.log(mode);
  }, []);

  const handleItemClick = (item) => {
    if (onItemClick) {
      onItemClick(item);
      // Close the sidebar in mobile mode
      if (isMobile) {
        onItemClick({ mode: "close" });
      }
    }
  };

  const renderLevels = (data) => {
    return data.map((item, index) => {
      if (item.type === "label") {
        if (
          item.permission === undefined ||
          item.permission.length === 0 ||
          item.permission.includes("") ||
          item.permission.includes(role)
        ) {
          return (
            <ListLabel key={index} mode={mode} className="sidenavHoverShow">
              {item.label}
            </ListLabel>
          );
        }
      }

      if (item.children) {
        const children = item.children.filter(
          (child) =>
            (!child.permission ||
              child.permission.length === 0 ||
              child.permission.every((p) => p === "") ||
              child.permission.includes(role)) &&
            (!item.permission ||
              item.permission.length === 0 ||
              item.permission.every((p) => p === "") ||
              item.permission.includes(role))
        );
        if (children.length === 0) {
          return null;
        }
        return (
          <MatxVerticalNavExpansionPanel mode={mode} item={item} key={index}>
            {renderLevels(children)}
          </MatxVerticalNavExpansionPanel>
        );
      } else if (item.type === "extLink") {
        if (
          item.permission === undefined ||
          item.permission.length === 0 ||
          item.permission.includes("") ||
          item.permission.includes(role)
        ) {
          return (
            <ExternalLink
              key={index}
              href={item.path}
              className={`${mode === "compact" && "compactNavItem"}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <ButtonBase key={item.name} name="child" sx={{ width: "100%" }}>
                {(() => {
                  if (item.icon) {
                    return (
                      <Icon className="icon" style={{ color: item.textColor }}>
                        {item.icon}
                      </Icon>
                    );
                  } else {
                    return (
                      <span
                        className="item-icon icon-text"
                        style={{ color: item.textColor }}
                      >
                        {item.iconText}
                      </span>
                    );
                  }
                })()}
                <StyledText
                  mode={mode}
                  className="sidenavHoverShow"
                  style={{ color: item.textColor }}
                >
                  {item.name}
                </StyledText>
                <Box mx="auto"></Box>
                {item.badge && <BadgeValue>{item.badge.value}</BadgeValue>}
              </ButtonBase>
            </ExternalLink>
          );
        }
      } else {
        if (
          item.permission === undefined ||
          item.permission.length === 0 ||
          item.permission.includes("") ||
          item.permission.includes(role)
        ) {
          return (
            <InternalLink key={index}>
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  isActive
                    ? `navItemActive ${mode === "compact" && "compactNavItem"}`
                    : `${mode === "compact" && "compactNavItem"}`
                }
                onClick={() => handleItemClick(item)}
              >
                 
                <ButtonBase key={item.name} name="child" sx={{ width: "100%" }}>
                  {item?.icon ? (
                    <Icon
                      className="icon"
                      sx={{ width: 36, color: item.textColor }}
                    >
                      {item.icon}
                    </Icon>
                  ) : (
                    <Fragment>
                      <BulletIcon
                        className={`nav-bullet`}
                        sx={{ display: mode === "compact" && "none" }}
                      />
                      <Box
                        className="nav-bullet-text"
                        sx={{
                          ml: "20px",
                          fontSize: "11px",
                          display: mode !== "compact" && "none",
                          color: item.textColor,
                        }}
                      >
                        {item.iconText}
                      </Box>
                    </Fragment>
                  )}
                  <StyledText
                    mode={mode}
                    className="sidenavHoverShow"
                    textColor={item.textColor}
                  >
                    {item.name}
                  </StyledText>

                  <Box mx="auto" />

                  {item.badge && (
                    <BadgeValue className="sidenavHoverShow">
                      {item.badge.value}
                    </BadgeValue>
                  )}
                </ButtonBase>
              </NavLink>
            </InternalLink>
          );
        }
      }
    });
  };

  return <div className="navigation">{renderLevels(items)}</div>;
};

export default React.memo(MatxVerticalNav);
