import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";
import { element } from "prop-types";


const AddCategory = Loadable(lazy(() => import("./orderThali/AddCategory")));
const CategoryList = Loadable(lazy(() => import("./orderThali/CategoryList")));
const AddFranchise = Loadable(lazy(() => import("./orderThali/AddFranchise")));
const FranchiseList = Loadable(
  lazy(() => import("./orderThali/FranchiseList"))
);
const AddRestaurant = Loadable(
  lazy(() => import("./orderThali/AddRestaurant"))
);
const ViewRestaurantDetails = Loadable(
  lazy(() => import("./orderThali/viewRestaurantDetails"))
);
const ResturantList = Loadable(
  lazy(() => import("./orderThali/RestaurantList"))
);
const AddAddons = Loadable(lazy(() => import("./orderThali/AddAddons")));
const AddonsList = Loadable(lazy(() => import("./orderThali/AddonsList")));
const AddCoupons = Loadable(lazy(() => import("./orderThali/AddCoupons")));
const CouponList = Loadable(lazy(() => import("./orderThali/CouponList")));
const CustomerList = Loadable(lazy(() => import("./orderThali/CustomerList")));
const DriverList = Loadable(lazy(() => import("./orderThali/DriverList")));

const CustomerTerms = Loadable(
  lazy(() => import("./orderThali/CustomerTerms"))
);
const DriverTerms = Loadable(lazy(() => import("./orderThali/DriverTerms")));
const ManagerTerms = Loadable(lazy(() => import("./orderThali/ManagerTerms")));
const AddCatalog = Loadable(lazy(() => import("./orderThali/AddCatalog")));
const CatalogList = Loadable(lazy(() => import("./orderThali/CatalogList")));
const ApprovalList = Loadable(lazy(() => import("./orderThali/ApprovalList")));
const Radius = Loadable(lazy(() => import("./orderThali/Radius")));
const Banner = Loadable(lazy(() => import("./orderThali/Banner")));
const BannerList = Loadable(lazy(() => import("./orderThali/BannerList")));
const Advertisement = Loadable(
  lazy(() => import("./orderThali/Advertisement"))
);
const AdvertisementList = Loadable(
  lazy(() => import("./orderThali/AdvertisementList"))
);
const DeliveryCharges = Loadable(
  lazy(() => import("./orderThali/DeliveryCharges"))
);
const Password = Loadable(lazy(() => import("./orderThali/Password")));
const Payouts = Loadable(lazy(() => import("./orderThali/Payouts")));
const AdminOrderDetail = Loadable(
  lazy(() => import("./orderThali/AdminOrderDetail"))
);
const WithdrawlPay = Loadable(lazy(() => import("./orderThali/WithdrawlPay")));
const DriverEdit = Loadable(lazy(() => import("./orderThali/EditDriver")));
const EditThali = Loadable(lazy(() => import("./orderThali/EditThali")));
const WithdrawlHistory = Loadable(
  lazy(() => import("./orderThali/WithdrawlHistory"))
);
const WithdrawlForm = Loadable(
  lazy(() => import("./orderThali/WithdrawlForm"))
);
const OrderItems = Loadable(lazy(() => import("./orderThali/OrderItems")));
const PeakHours = Loadable(lazy(() => import("./orderThali/Peakhours")));
const OrderRoute = Loadable(lazy(() => import("./orderThali/OrderRoute")));
const AddPin = Loadable(lazy(() => import("./orderThali/AddPin")));
const GeoFenc = Loadable(lazy(() => import("./orderThali/GeoFenc")));
const ThaliAddons = Loadable(lazy(() => import("./orderThali/ThaliAddons")));
const DriverDetails = Loadable(
  lazy(() => import("./orderThali/DriverDetails"))
);
const RestaurantFenc = Loadable(
  lazy(() => import("./orderThali/RestaurantFenc"))
);
const EditPortion = Loadable(lazy(() => import("./orderThali/EditPortion")));
const FestiveOffers = Loadable(
  lazy(() => import("./orderThali/FestiveOffers"))
);
const FestiveOfferList = Loadable(
  lazy(() => import("./orderThali/FestiveofferList"))
);
const DriverBasePrice = Loadable(lazy(() => import("./orderThali/DriverBase")));
const DriverPayoutSlab = Loadable(
  lazy(() => import("./orderThali/DriverPayoutSlab"))
);
const AdminMargin = Loadable(lazy(() => import("./orderThali/AdminMargin")));
const CustomerRewards =Loadable(lazy(()=>import("./orderThali/CustomerRewards")))
const DriverRewards = Loadable(lazy(()=>import("./orderThali/DriverRewards")))
const RestaurantRewards =Loadable(lazy(()=>import('./orderThali/RestaurantRewards')))
const RewardTerms = Loadable (lazy(()=>import('./orderThali/RewardTerms')))
const FranhisePayoutSlab = Loadable(lazy(()=>import('./orderThali/FranchiseSlab')))
const RefrelAdd = Loadable(lazy(()=>import('./orderThali/RefrelAdd')))
const RefrelList = Loadable(lazy(()=>import('./orderThali/RefrelList')))
const RefrelEarn = Loadable(lazy(()=>import('./orderThali/RefreEarn')))
const UserReferralList =Loadable(lazy(()=>import('./orderThali/UserReferralList')))
const RestaurantEarning =Loadable(lazy(()=>import('./orderThali/RestaurantEarning')))
const RestaurantEarningAdmin =Loadable(lazy(()=>import('./orderThali/RestaurantEarningAdmin')))
const RestaurantCouponList =Loadable(lazy(()=>import('./orderThali/RestroCouponList')))

const materialRoutes = [
  { path: "/restrocouponlist", element: <RestaurantCouponList />, auth: authRoles.sa },
  { path: "/adminmargin", element: <AdminMargin />, auth: authRoles.sa },
  { path: "/restaurantearningadmin", element: <RestaurantEarningAdmin />, auth: authRoles.sa },
  { path: "/restaurantearning", element: <RestaurantEarning />, auth: authRoles.franchise },
  { path: "/userreferrallist", element: <UserReferralList />, auth: authRoles.sa },

  {path:'/ReferrelAdd',
    element:<RefrelAdd/>,
    auth:authRoles.sa
  },
  {path:'/ReferrelAdd/:id',
    element:<RefrelAdd/>,
    auth:authRoles.sa
  },
  {path:'/ReferrelList',
    element:<RefrelList/>,
    auth:authRoles.sa
  },
  {path:'/ReferralEarn',
    element:<RefrelEarn/>,
    auth:authRoles.sa
  },

  {path:'/franchisePayoutSlab',
    element:<FranhisePayoutSlab/>,
    auth:authRoles.sa
  },
  {
    path:'/rewardTerms',
    element:<RewardTerms/>,
    auth:authRoles.sa
  },
  {
    path:'/restaurantreward',
    element:<RestaurantRewards/>,
    auth:authRoles.sa
  },
  {
    path:'/customerreward',
    element:<CustomerRewards/>,
    auth:authRoles.sa
  },
  {
    path:'/driverreward',
    element:<DriverRewards/>,
    auth:authRoles.sa
  },
  {
    path: "/driverslab",
    element: <DriverPayoutSlab />,
    auth: authRoles.sa,
  },
  {
    path: "/driverBasePrice",
    element: <DriverBasePrice />,
    auth: authRoles.sa,
  },
  {
    path: "/festiveofferlist",
    element: <FestiveOfferList />,
    auth: authRoles.sa,
  },

  {
    path: "/festiveoffers",
    element: <FestiveOffers />,
    auth: authRoles.sa,
  },
  {
    path: "/festiveoffers/:id",
    element: <FestiveOffers />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/radius",
    element: <Radius />,
    auth: authRoles.sa,
  },
  {
    path: "/editportion/:id",
    element: <EditPortion />,
    auth: authRoles.sa,
  },
  {
    path: "/addpin",
    element: <AddPin />,
    auth: authRoles.sa,
  },

  {
    path: "/OrderRoute/:id",
    element: <OrderRoute />,
    auth: authRoles.sa,
  },

  {
    path: "/material/orderThali/geofence",
    element: <GeoFenc />,
    auth: authRoles.sa,
  },
  {
    path: "/orderThali/restaurantfence",
    element: <RestaurantFenc />,
    auth: authRoles.sa,
  },

  {
    path: "/editDriver/:id",
    element: <DriverEdit />,
    auth: authRoles.sa,
  },
  {
    path: "/driverdetails/:id/:frid",
    element: <DriverDetails />,
    auth: authRoles.Super_employee,
  },
  {
    path: "/editThali/:id",
    element: <EditThali />,
    auth: authRoles.sa,
  },
  {
    path: "/OrderItems/:id",
    element: <OrderItems />,
    auth: authRoles.sa,
  },

  {
    path: "/ThaliAddons/:id",
    element: <ThaliAddons />,
    auth: authRoles.sa,
  },

  {
    path: "/material/orderThali/banner",
    element: <Banner />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/password",
    element: <Password />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/peakhours",
    element: <PeakHours />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/adminorderdetail",
    element: <AdminOrderDetail />,
    auth: authRoles.super_franc_employe,
  },
  {
    path: "/material/orderThali/payouts",
    element: <Payouts />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/banner/:id",
    element: <Banner />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/bannerlist",
    element: <BannerList />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/advertisement",
    element: <Advertisement />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/deliverycharges",
    element: <DeliveryCharges />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/advertisement/:id",
    element: <Advertisement />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/advertisementlist",
    element: <AdvertisementList />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/addcategory",
    element: <AddCategory />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/addcatlog",
    element: <AddCatalog />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/catalogList",
    element: <CatalogList />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/addcatlog/:id",
    element: <AddCatalog />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/addcategory/:id",
    element: <AddCategory />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/categorylist",
    element: <CategoryList />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/addfranchise",
    element: <AddFranchise />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/withdrawlpay",
    element: <WithdrawlPay />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/withdrawlhistory",
    element: <WithdrawlHistory />,
    auth: authRoles.sa,
  },

  {
    path: "/material/orderThali/withdrawlform",
    element: <WithdrawlForm />,
    auth: authRoles.sa,
  },
  // {
  //   path: "/material/orderThali/AddThali/:id",
  //   element: <AddThalinew />,
  //   auth: authRoles.restaurant,
  // },
  // {
  //   path: "/material/orderThali/ThaliList",
  //   element: <ThaliListnew />,
  //   auth: authRoles.restaurant,
  // },
  {
    path: "/material/orderThali/addfranchise/:id",
    element: <AddFranchise />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/franchiselist",
    element: <FranchiseList />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/addrestaurant",
    element: <AddRestaurant />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/addrestaurant/:id",
    element: <AddRestaurant />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/viewRestaurantDetails/:id",
    element: <ViewRestaurantDetails />,
    auth: authRoles.four,
  },
  {
    path: "/material/orderThali/restaurantlist",
    element: <ResturantList />,
    auth: authRoles.four,
  },
  {
    path: "/material/orderThali/addADDONS",
    element: <AddAddons />,
    // auth: authRoles.Super_restaurant,
  },
  {
    path: "/material/orderThali/addADDONS/:id",
    element: <AddAddons />,
    // auth: authRoles.Super_restaurant,
  },
  {
    path: "/material/orderThali/addonslist",
    element: <AddonsList />,
    // auth: authRoles.restaurant,
  },
  {
    path: "/material/orderThali/customerlist",
    element: <CustomerList />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/driverlist",
    element: <DriverList />,
    auth: authRoles.four,
  },
  {
    path: "/material/orderThali/addcoupons",
    element: <AddCoupons />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/addcoupons/:id",
    element: <AddCoupons />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/couponslist",
    element: <CouponList />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/approvallist",
    element: <ApprovalList />,
    auth: authRoles.Super_employee,
  },
  {
    path: "/material/orderThali/customerterms",
    element: <CustomerTerms />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/managerterms",
    element: <ManagerTerms />,
    auth: authRoles.sa,
  },
  {
    path: "/material/orderThali/driverterms",
    element: <DriverTerms />,
    auth: authRoles.sa,
  },
];

export default materialRoutes;
