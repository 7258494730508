import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "app/hooks/useAuth";
const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const socket = io(process.env.REACT_APP_SOCKET_URL, {
    transports: ["websocket", "polling"],
  });

  const [playNotification, setPlayNotification] = useState(false);
  const sound = process.env.REACT_APP_SOUND;
  const { user } = useAuth();


  // const playNotificationSound = () => {
  //   const audio = new Audio(sound);
  //   audio?.play().catch((error) => {
  //     console.error("Error playing audio:", error);
  //   });
  // };

  const playNotificationSound = () => {
    const audio = new Audio(sound);
    audio.autoplay=true
    audio.load();
  };

 
  console?.log(user);

  useEffect(() => {
    socket.on("globalNotification", (data) => {
      toast.success(data);
    });

    socket.on("soundNotification", (data) => {
      console.log(data)
      toast.success(data);
      playNotificationSound();
    });

    socket.emit("pim", (data) => {
      console.log("sent data to server:", data);
      toast.success(data);
    });

    socket.on("pam", (data) => {
      console.log("Received data from server:", data);
      toast.success(data);
      
    });
    return () => {
      socket.disconnect();
      toast.error("socket disconnect");
    };
  }, [playNotification]);

  useEffect(() => {
    if (playNotification) {
      playNotificationSound();
      setPlayNotification(false); // Reset flag after playing sound
    }
  }, [playNotification]);

  return (
    <SocketContext.Provider value={socket}>
      <ToastContainer autoClose={true} />
      {children}
    </SocketContext.Provider>
  );
};
